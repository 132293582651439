var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "border-radius--10px",
      attrs: { value: _vm.show, "max-width": "400px" },
      on: { close: _vm.close, "click:outside": _vm.close },
    },
    [
      _c(
        "v-img",
        {
          attrs: {
            src: require("@/assets/images/backgrounds/bg-erroe-msg.svg"),
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center height--full" },
            [
              _c("div", { staticClass: "mew-heading-2 white--text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("errorsGlobal.something-went-wrong")) +
                    " "
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "py-6 bgWalletBlock" },
        [
          _c(
            "div",
            { staticClass: "px-6" },
            [
              _c("div", [
                _vm._v(
                  " Something went wrong, would you like to inform to #XinFinTeam about this error? "
                ),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-4 mb-2" },
                [
                  _c(
                    "v-col",
                    [
                      _c("mew-button", {
                        attrs: {
                          "has-full-width": true,
                          "btn-style": "outline",
                          "btn-size": "xlarge",
                          title: "No",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.resolve(false)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("mew-button", {
                        attrs: {
                          "has-full-width": true,
                          "btn-size": "xlarge",
                          title: "Send",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.resolve(true)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-6" }),
          _c(
            "v-expansion-panels",
            { attrs: { flat: "" } },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "bgWalletBlock" },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "font-weight-bold" },
                    [_vm._v(" Error messages ")]
                  ),
                  _c("v-expansion-panel-content", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.errorDetails,
                          expression: "errorDetails",
                        },
                      ],
                      staticClass: "error-detail px-8 pb-6 textDark--text",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.errorDetails },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.errorDetails = $event.target.value
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-6" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }